<template>
    <admin-dashboard-layout>
        <v-card color="primary" dark>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{coin.name}}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.coins.index'}" color="grey">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name"  v-model="coin.name" :error-messages="errors.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea label="Contract" rows="3" row-height="20px" outlined v-model="coin.contract" :error-messages="errors.contract"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <vue-dropzone

                                ref="myVueDropzone" id="dropzone"
                                :options="dropzoneOptions"
                                @vdropzone-success="handleSuccess"
                                @vdropzone-upload-progress="uploadProgress"
                                @vdropzone-complete="uploadFinished"
                                @vdropzone-removed-file="removedFile"
                            ></vue-dropzone>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-switch label="Active" v-model="coin.is_active"  />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center">
                            <v-btn @click="handleSave" :loading="isLoading" :disabled="uploadingFile" color="grey">  Save </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Coin from "@/models/Coin";
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: "CoinsEdit",
        components: {AdminDashboardLayout, vueDropzone: vue2Dropzone},
        data: function () {
            return {
                coin: {},
                errors: {},
                isLoading: false,
                dropzoneOptions: {
                    url: '/api/upload',
                    thumbnailWidth: 250,
                    maxFilesize: 5,
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: {
                        Authorization: null
                    }
                },
                uploadingFile: false
            }
        },
        methods:{
            async handleSave() {
                this.isLoading = true;
                const coin = new Coin(this.coin)
                await coin.save().then(() => {
                    this.$router.push({name: 'admin.coins.index'})
                }).catch((error) => {
                    this.errors = error.response.data.errors || {}
                })
                .finally(() => this.isLoading = false)
            },
            handleSuccess(file, res) {
                if (res.name) {
                    this.coin.avatar_url = res.name
                } else {
                    alert('Error while for uploading image')
                    this.coin.avatar_url = null;
                    this.$refs.myVueDropzone.removeAllFiles();
                }

            },
            removedFile() {
                this.coin.avatar_url = null;
            },
            uploadProgress() {
                this.uploadingFile = true;
            },
            uploadFinished() {
                this.uploadingFile = false;
            }
        },
        async mounted() {
            const token = await localStorage.getItem('auth_token_default')
            this.dropzoneOptions.headers.Authorization = "Bearer "+token

            if (this.$route.params.id) {
                const coinId = this.$route.params.id
                this.coin = await Coin.find(coinId)

                if (this.coin.avatar_url) {
                    this.$refs.myVueDropzone.removeAllFiles();
                    let file = {
                        size: 123,
                        name: `/storage/images/${this.coin.avatar_url}`,
                        url: `/storage/images/${this.coin.avatar_url}`,
                        thumbnail: `/storage/images/${this.coin.avatar_url}`
                    };
                    this.$refs.myVueDropzone.manuallyAddFile(file, `/storage/images/${this.coin.avatar_url}`);
                }
            }
        },
    }
</script>

<style scoped>

</style>
