<template>
    <v-main>
        <top-menu />
        <slot class="bg-lighten"> <!--CONTENT--> </slot>
        <bottom-menu />
    </v-main>
</template>

<script>
    import TopMenu from "../components/TopMenu";
    import BottomMenu from "../components/BottomMenu"

    export default {
        name: "front-layout",
        components: {TopMenu, BottomMenu},
        data: function () {
            return {}
        },
    }
</script>

<style scoped>
    *{
        background-color: #3a3534;
        color: white;
    }
</style>
