<template>
    <admin-dashboard-layout>
        <div class="white--text">
            admin.Index
        </div>
    </admin-dashboard-layout>
</template>

<script>

    import AdminDashboardLayout from "../../layouts/AdminDashboardLayout";

    export default {
        name: "Index",
        components: {AdminDashboardLayout},
        data: function () {
            return {}
        },
    }
</script>

<style>
.bg-lighten{
    background-color: #3a3534;
    color: white;
}
</style>
