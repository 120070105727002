<template>
    <v-card style="cursor: pointer; position: relative" hover color="primary" dark>
        <div @click="$emit('click')">
            <v-card-title v-if="coin.visible_promoted" class="d-flex justify-center ma-0 pa-0">

                <v-chip
                    class="ma-2"

                >
                    <v-icon style="position: absolute; left:4px;">mdi-star</v-icon>
                    <span class="pl-4">Promoted</span>
                </v-chip>
            </v-card-title>
            <v-card-text class="d-flex align-center" style="width: 100%">
                <v-avatar>
                    <v-img v-if="coin.avatar_url" :src="`/storage/images/${coin.avatar_url}`"/>
                    <v-img v-else src="@/assets/image-placeholder.jpg"/>
                </v-avatar>
                <v-card-title class="d-flex flex-column" >
                    <div>
                        {{coin.name}} ({{ coin.symbol }})
                    </div>

                </v-card-title>

            </v-card-text>
        </div>
        <v-btn
            v-if="!coin.has_vote"
            :loading="voteLoading"
            @click="handleUpvote"
            class="ml-auto align-self-center"
            outlined
            color="default"
            style="    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -18px;"
        >
            <v-icon class="mr-2" >mdi-thumb-up</v-icon>
            {{ coin.votes_count }}
        </v-btn>
        <v-btn
            v-else
            class="ml-auto align-self-center"
            disabled
            color="success"
            style="    position: absolute; background-color: var(--v-success-base) !important;
    border-color: var(--v-success-base) !important; color:white !important;
    right: 20px;
    top: 50%;
    margin-top: -18px;"
        >
            <v-icon class="mr-2" style=" color:white !important;">mdi-thumb-up</v-icon>
            {{ coin.votes_count }}
        </v-btn>
    </v-card>
</template>

<script>
    import Coin from "@/models/Coin";
    import Upvote from "@/models/Upvote";

export default {
    name: "CoinCard",
    props: ['coin'],
    data() {
        return {
            voteLoading: false,
        }
    },
    methods: {
        async handleUpvote() {
            this.voteLoading = true;
            const upvote = new Upvote().for(new Coin(this.coin))

            await upvote.save().then(() => {
                this.coin.has_vote = true;
                this.coin.votes_count += 1;
            }).catch(() => {

            });

            this.voteLoading = false;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
