<template>
    <front-layout>
        <v-container >
            <v-row class="text-center mb-12">
                <v-col>
                    Disclaimer
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "@/layouts/FrontLayout";

    export default {
        name: 'TermsConditions',
        components: {FrontLayout},
    }
</script>

<style scoped>

</style>