<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right" >
                <v-btn @click="$router.push({path: '/admin/create/coins'})" color="grey" dark>Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            color="secondary" dark
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="coins"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at }} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Coin from "@/models/Coin";

    export default {
        name: "users-index",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                coins: [],
                loading: false,
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Name', value: 'name' },
                ],
            }
        },
        methods: {
            handleClickRow(plan) {
                this.$router.push({name: 'admin.coins.edit', params: {id: plan.id}})
            }
        },
        async mounted() {
            this.loading = true
            this.coins = await Coin.orderBy('-created_at').get()
            this.loading = false

        },
    }
</script>

<style scoped>

</style>
