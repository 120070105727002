<template>
    <admin-dashboard-layout>
        <v-card color="primary" dark>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{coin.name}}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.coins.index'}" color="grey">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name" v-model="coin.name"
                                          :error-messages="errors.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Symbol" v-model="coin.symbol"
                                          :error-messages="errors.symbol"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea label="Contract" rows="3" row-height="20px" outlined v-model="coin.contract" :error-messages="errors.contract"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
<!--                            <label for="">Logo</label>-->
                            <vue-dropzone
                                dark
                                color="primary"
                                label="asd"
                                ref="myVueDropzone" id="dropzone"
                                :options="dropzoneOptions"
                                @vdropzone-success="handleSuccess"
                                @vdropzone-upload-progress="uploadProgress"
                                @vdropzone-complete="uploadFinished"
                                @vdropzone-removed-file="removedFile"
                            ></vue-dropzone>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-switch label="Active" v-model="coin.is_active"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center">
                            <v-btn @click="handleCreate" :loading="isLoading" :disabled="uploadingFile" color="grey">
                                Create
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from '../../../layouts/AdminDashboardLayout'
    import Coin from '@/models/Coin'
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: 'Create',
        components: { AdminDashboardLayout,  vueDropzone: vue2Dropzone },
        data () {
            return {
                coin: {
                    name: null,
                    is_active: false,
                    symbol: null,
                    avatar_url: null,
                    contract: null
                },
                errors: {},
                isLoading: false,
                dropzoneOptions: {
                    url: '/api/upload',
                    thumbnailWidth: 250,
                    maxFilesize: 5,
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: {
                        Authorization: null
                    }
                },
                uploadingFile: false

            }
        },
        methods: {
            async handleCreate () {
                this.isLoading = true

                const coin = new Coin(this.coin)
                await coin.save().then(() => {
                    this.$router.push({name: 'admin.coins.index'})
                }).catch((error) => {
                    this.errors = error.response.data.errors || {}
                }).finally(() => {
                    this.isLoading = false
                })
            },
            handleSuccess(file, res) {
                if (res.name) {
                    this.coin.avatar_url = res.name
                } else {
                    alert('Error while for uploading image')
                    this.coin.avatar_url = null;
                    this.$refs.myVueDropzone.removeAllFiles();
                }

            },
            uploadProgress() {
                this.uploadingFile = true;
            },
            uploadFinished() {
                this.uploadingFile = false;
            },
            removedFile() {
                this.coin.avatar_url = null;
            },
        },
        async mounted() {
            const token = await localStorage.getItem('auth_token_default')
            this.dropzoneOptions.headers.Authorization = "Bearer "+token
        }
    }
</script>

<style scoped>

</style>