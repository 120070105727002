<template>
    <v-footer
        absolute
        color="primary"
        dark>
    <div class="d-flex align-center justify-center" style="width:100%;">
        <router-link class="white--text footer__link"  :to="{name: 'disclaimer'}">Disclaimer</router-link>
        <router-link class="white--text footer__link" :to="{name: 'privacy-policy'}">Privacy Policy</router-link>
        <router-link class="white--text footer__link" :to="{name: 'terms-conditions'}">Terms & Conditions</router-link>
    </div>
    </v-footer>
</template>

<script>
    export default {
        name: 'BottomMenu.vue',
    }
</script>

<style scoped lang="scss">
.footer__link{
    text-decoration: none;
    margin-right: 30px;
    padding: 10px;
    border-radius: 5px;
    &:last-child{
    margin-right: 0;
     }
    &:hover{
        opacity: .75;
    }
    &.router-link-active {
        opacity: .8;
        background: #ffffff26;
    }
}
</style>