<template>
    <front-layout>
        <v-container class="mt-6">
            <v-row class="text-center mb-12">
                <v-tabs hide-slider background-color="secondary" dark height="25">
                    <v-tab>Promoted</v-tab>
                </v-tabs>
                <v-col cols="12" v-for="coin in coinsPromoted"  :key="`coin-`+coin.id">
                    <coin-card :coin="coin" @click="push(coin.symbol)"/>
                </v-col>
            </v-row>
            <v-row class="text-center mb-12">
                <v-tabs hide-slider background-color="secondary" dark height="25">
                    <v-tab >All Time</v-tab>
                    <v-tab>Today</v-tab>
                    <v-tab>Soon Launch</v-tab>
                </v-tabs>
                <v-col cols="12" v-for="coin in coins"  :key="`coin-`+coin.id">
                    <coin-card :coin="coin" @click="push(coin.symbol)"/>
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "../layouts/FrontLayout";
    import Coin from "@/models/Coin";
    import CoinCard from "@/components/CoinCard";
    import { mapState } from "vuex";

    export default {
        name: 'Home',
        components: {FrontLayout, CoinCard},
        data: function () {
            return {
                coinsRep: []
            }
        },
        methods: {
            push(symbol) {
                this.$router.replace({name: 'coins.show', params: { symbol }})
            },
            async fetchItems() {
                this.coinsRep = await Coin.custom('coins/public').where('is_active', 1).get()
            }
        },
        computed: {
            ...mapState({
                searchText: state => state.site.searchText
            }),
            coins() {
                return this.searchText ? this.coinsRep.filter(item => item.name && item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) : this.coinsRep;
            },
            coinsPromoted(){
                return this.coins.filter(item=>item.is_promoted == 1);
            }

        },
        async mounted() {
            this.fetchItems()
            console.log(this.coins)
        }
    }
</script>
<style scoped lang="scss">

</style>
