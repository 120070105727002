<template>
    <v-app-bar
        app
        color="primary"
        dark
        clipped-left
    >
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')"
                            v-if="isDashboard"
        />
        <router-link :to="{name: 'home'}" style="height: 100%">
            <div class="d-flex align-center">
                <v-img
                    alt="Logo"
                    class="shrink mr-2 rounded-circle"
                    contain
                    src="@/assets/logo-transparent.png"
                    transition="scale-transition"
                    width="70"
                    height="100%"
                />
            </div>
        </router-link>

    <div class="container-search" style="width: 100%; margin: 0 150px 0 10px;">
        <v-text-field placeholder="Search"
                      class="ml-5 container-search__input"
                      single-line
                      hide-details
                      v-model="searchText"
                      @keyup.enter="handleSearch"
                      @keyup.esc="cancelSearch"

        ></v-text-field>
        <div class="container-search__box-for-icons">
            <v-icon class="container-search__icon" @click="handleSearch">mdi-magnify</v-icon>
            <v-icon class="container-search__icon" @click="cancelSearch">mdi-close</v-icon></div>
    </div>

<!--
<div class="switch-theme" style="margin-right: 30px">
        <v-switch
            class="switch-theme__btn"
            color="white"
            v-model="$vuetify.theme.dark"
            inset
            hide-details
            label="Dark"
            persistent-hint
        ></v-switch>
    </div>-->
        <v-spacer></v-spacer>

        <auth-user-dropdown v-if="$auth.user()" />
        <v-btn text :to="{name: 'login'}" v-else>Login</v-btn>
    </v-app-bar>
</template>
<script>
    import { mapMutations} from "vuex";
    import AuthUserDropdown from "./AuthUserDropdown";
    export default {
        name: "top-menu",
        props: {
            isDashboard: {
                default: false,
                type: Boolean
            }
        },
        computed: {
            appName() {
                return process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "Saas"
            }
        },
        components: {AuthUserDropdown},
        methods: {
            ...mapMutations(['setSearchText']),
            handleSearch() {
                this.setSearchText(this.searchText);
                if (this.searchText && this.searchText.length) {
                    this.$router.push({name: 'home', query: { search: this.searchText }})
                } else {
                    this.$router.push({name: 'home', query: { }})
                }
            },
            cancelSearch(){
                this.searchText = "";
                this.setSearchText("");
                this.$router.push({name: 'home', query: { }})
            }
        },
        data: function () {
            return {
                searchText: null
            }
        },
        mounted () {
            if (this.$route.query.search) {
                this.searchText = this.$route.query.search;
                this.setSearchText(this.searchText);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .container-search{
        position: relative;
        &__input{
            background: #d2d2d226;
            padding: 6px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

        }
        &__box-for-icons{
            position: absolute;
            right: -67px;
            background-color: #d2d2d226;;
            padding: 6px;
            top: 50%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            transform:translateY(-50%);
        }
        &__icon{
            margin:0 0 1px 2px;
            cursor: pointer;
            &:last-child{
                margin-left: 5px;
            }
        }

    }
</style>
