const state = {
    snackbar: {},
    searchText: null
};

const getters = {

};

const mutations = {
    hideSnackBar: (state) => {
        state.snackbar = {...state.snackbar, text: '', show: false}
    },
    showSnackBar: (state, snackbar) => {
        state.snackbar = {...snackbar, show: true}
    },
    setSearchText(state, payload) {
        state.searchText = payload;
    }
};

const actions = {

};

export default {
    state, getters, mutations, actions
}
