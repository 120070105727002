<template>
    <front-layout>

        <v-container>
            <v-row>
                <v-col>
                    <v-card color="primary" dark>
                        <v-progress-linear
                            :active="isLoading"
                            :indeterminate="isLoading"
                            absolute
                            top
                            color="deep-purple accent-4"
                        ></v-progress-linear>
                        <template v-if="coin.name" >
                            <div class="d-flex flex-no-wrap justify-left">
                                <v-avatar
                                    class="ma-3"
                                    size="125"
                                    tile
                                >
                                    <v-img v-if="coin.avatar_url" :src="`/storage/images/${coin.avatar_url}`"></v-img>
                                    <v-img v-else src="@/assets/image-placeholder.jpg"/>
                                </v-avatar>
                                <v-card-title >{{coin.name}} ({{ coin.symbol }}) Hello</v-card-title>
                            </div>
                            <div>
                                <v-card-subtitle>
                                   {{  coin.contract }}
                                </v-card-subtitle>
                            </div>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "../layouts/FrontLayout";
    import Coin from "@/models/Coin";

    export default {
        name: 'CoinShow',
        components: { FrontLayout },
        data() {
            return {
                coin: {},
                isLoading: false
            }
        },
        async mounted () {
            if (this.$route.params.symbol) {
                this.isLoading = true
                const symbol = this.$route.params.symbol
                this.coin = await Coin.custom('coins/public').where('symbol', symbol).first()
                this.isLoading = false;
            }
        }
    }
</script>

<style scoped>

</style>